.Servicios{
    display: flex;
    place-content: center;
    justify-content: space-between;
}

.servicio {
    width: 25%;
    height: auto;
    background-color: var(--blanco);
    padding: 15px;
    border-radius: 15px;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.071);
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    gap: 10px;
    margin: 1rem 1rem 1rem 10px;
    justify-content: space-around;

}

.servicio img{
    width: 100px;
    border-radius: 100%;
   border: 10px solid var(--color1);
}
.servicio h2{
    color: var(--color1);
    font-size: 30px;
}
.servicio h3{
    color: var(--text-color2);
    font-size: 23px;
}
.servicio p{
    color: var(--text-color2);
    font-size: 17px;
    
    font-weight: 600;
    line-height: 1.7rem;
}

.deFlexBtnServis{
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    width: 100%;
    margin-top: 2rem;
}
.deFlexBtnServis button{
    width: 100%;
}

.wppBtnServ{
    padding: 7px 20px;
    border-radius: 10rem;
    background-color: rgb(30, 184, 30);
    border: none;
    color: var(--blanco);
    font-size: 15px;
}
.btnServ{
    padding: 7px 20px;
    border-radius: 10rem;
    background-color:var(--color1);
    border: none;
    color: var(--blanco);
    width: 100%;
    font-size: 15px;
}

.servicio:hover{
    background-color: var(--color1);

}
.servicio:hover p{
   color: var(--blanco);

}
.servicio:hover h2{
    color: var(--blanco);
 
 }
 .servicio:hover h3{
    color: var(--blanco);
 
 }
 .servicio:hover .btnServ{
    background-color: var(--blanco);
    color: var(--color1);
 }
 .servicio:hover img{
    border: 10px solid var(--blanco);
 
 }
 .activeSwiper{
    background-color: var(--color1);
}
.activeSwiper{
    background-color: var(--color1);

}
.activeSwiper p{
   color: var(--blanco);

}
.activeSwiper h2{
    color: var(--blanco);
 
 }
 .activeSwiper h3{
    color: var(--blanco);
 
 }
 .activeSwiper .btnServ{
    background-color: var(--blanco);
    color: var(--color1);
 }
 .activeSwiper img{
    border: 10px solid var(--blanco);
 
 }

 @media (max-width: 900px) {
    .Servicios{
        padding: 10px 2% 0;
        overflow: hidden;
        overflow-x: scroll;
       
    }
    .servicio img{
        width: 70px;
        border-radius: 100%;
       border: 7px solid var(--color1);
    }
    .servicio h2{
       font-size: 17px;
    }
    .servicio h3{
        font-size: 17px;
    }
    .servicio p{
       
        font-size: 12px;
       
        line-height: 1.3rem ;
    }
    .servicio {
        width: 17rem;
        margin: 1rem 1rem 1rem 10px;
    }    
 }